import { Platform } from "@/config/platform";
import { getCurrentInstance, inject } from "vue";

export function usePlatform(): Platform {
    const platform = inject<Platform>("platform");
    if (!platform) throw new Error("must be called in setup");
    return platform;
}

export function useIcon(type: string) {
    const inst = getCurrentInstance();
}

/** 判断当前设备是不是pc */
export function isPc() {
    return !navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
}
