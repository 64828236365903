import { defineComponent, h, PropType } from "vue";
import { Badge, Radio } from "@intasect/ant-design-vue";
import { computed } from "@vue/reactivity";

export type ResourceType = { label: string; value: string };

export const EnableStatus = [
    {
        label: "启用",
        value: 1,
    },
    {
        label: "停用",
        value: 0,
    },
];

export const resourceMap = {
    EnableStatus,
};

export const ResourceTag = defineComponent({
    props: {
        value: {
            type: String,
            required: true,
        },
        resource: {
            type: String as PropType<keyof typeof resourceMap>,
            required: true,
        },
    },
    setup(props) {
        const currentStatus = computed(() => {
            return resourceMap[props.resource].find(
                a => a.value === props.value
            );
        });

        const text = computed(() => {
            if (currentStatus.value) {
                return currentStatus.value.label;
            }
            return props.value;
        });
        const status = computed(() => {
            let status = "default";
            if (currentStatus.value) {
                switch (currentStatus.value.value) {
                    case 1:
                        status = "success";
                        break;
                    case 0:
                        status = "error";
                        break;
                }
            }
            return status;
        });
        //@ts-ignore
        return () => h(Badge, { status: status.value, text: text.value });
    },
});

export const ResourceRadio = defineComponent({
    props: {
        modelValue: String,
        resource: {
            type: String as PropType<keyof typeof resourceMap>,
            required: true,
        },
    },
    setup(props, context) {
        return () =>
            h(
                Radio.Group,
                {
                    value: props.modelValue,
                    "onUpdate:value": (value: string) => {
                        context.emit("update:modelValue", value);
                    },
                },
                {
                    default: () =>
                        resourceMap[props.resource].map(item => {
                            return h(
                                Radio,
                                { value: item.value },
                                { default: () => item.label }
                            );
                        }),
                }
            );
    },
});
