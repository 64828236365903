const hexes = new Array(
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F"
);
export const loginEncrypt = function (s: string) {
    let r = "";
    for (var i = 0; i < s.length; i++) {
        r += hexes[s.charCodeAt(i) >> 4] + hexes[s.charCodeAt(i) & 0xf];
    }
    return r;
};
